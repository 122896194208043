<template>
  <div
    style="display: block"
  >
    {{ baseData.baseData?.test }}
  </div>
  <div
    id="appApage"
    class="bg-dark"
  >
    <cookie />
    <main>
      <router-view />
    </main>
    <playback />
    <page-footer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Playback from '@/components/InDevelopment/Playback.vue';
import Cookie from '@/components/Cookie.vue';
import PageFooter from '@/components/Allgemein/PageFooter.vue';
import { BaseData } from '@/data_models/BaseData';

export default defineComponent({
  name: 'AgapeMain',
  components: {
    PageFooter,
    Playback,
    Cookie
  },
  data () {
    return {
      baseData: BaseData
    };
  }
});

</script>

<style lang="scss" >
  @import "style/spectre_custom";
  @import "style/base.scss";

  // Import full Spectre source code
  @import "node_modules/spectre.css/src/spectre";
  @import "node_modules/spectre.css/src/spectre-exp";
  @import "node_modules/spectre.css/src/spectre-icons";

  @import "style/agape_panels";

  body {
    min-height: 100%;
    background-color: #000000;
  }

  #app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #appApage {
    min-height: 100vh;
    display: flex;
    flex-flow: column;

    main {
      flex-grow: 1;
    }
  }
</style>
